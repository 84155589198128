const ConditionalInElement = <template>
  {{#if @inline}}
    {{yield}}
  {{else if @element}}
    {{#if @append}}
      {{#in-element @element insertBefore=null}}{{yield}}{{/in-element}}
    {{else}}
      {{#in-element @element}}{{yield}}{{/in-element}}
    {{/if}}
  {{/if}}
</template>;
export default ConditionalInElement;
